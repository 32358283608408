// ** React Imports
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power, Lock } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Modal, ModalHeader, ModalBody, Form, Button, Label, Input, FormFeedback } from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg'
import { useForm, Controller } from 'react-hook-form'

// ** yup
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Fragment } from 'react'
import { isObjEmpty } from '../../../../utility/Utils'
import { changePass } from '../../../../redux/user'
import { useTranslation } from 'react-i18next'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const {t} = useTranslation()
  // ** State
  const [userData, setUserData] = useState(null)
  const [modal, setModal] = useState(false)
  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar
  const toggle = () => {
    setModal(!modal)
  }
  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{(userData && userData['username']) || 'John Doe'}</span>
          <span className='user-status mt-50 text-capitalize'>{(userData && userData.role) || 'Admin'}</span>
        </div>
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu end>
        {/* <DropdownItem tag={Link} to='/pages/profile'>
          <User size={14} className='me-75' />
          <span className='align-middle'>Profile</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/apps/email'>
          <Mail size={14} className='me-75' />
          <span className='align-middle'>Inbox</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/apps/todo'>
          <CheckSquare size={14} className='me-75' />
          <span className='align-middle'>Tasks</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/apps/chat'>
          <MessageSquare size={14} className='me-75' />
          <span className='align-middle'>Chats</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to='/pages/account-settings'>
          <Settings size={14} className='me-75' />
          <span className='align-middle'>Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/pages/pricing'>
          <CreditCard size={14} className='me-75' />
          <span className='align-middle'>Pricing</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/pages/faq'>
          <HelpCircle size={14} className='me-75' />
          <span className='align-middle'>FAQ</span>
        </DropdownItem> */}
        {/* <DropdownItem 
        onClick={() => toggle()}
        >
          <Lock size={14} className='me-75' />
          <span className='align-middle'>Change Password</span>
        </DropdownItem> */}
        <DropdownItem tag={Link} to='/login' onClick={() => dispatch(handleLogout())}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>{t('Logout')}</span>
        </DropdownItem>
      </DropdownMenu>
      <ChangeComponent
        modal={modal}
        toggle={toggle}
        dispatch={dispatch}
      />
    </UncontrolledDropdown>
  )
}
const ChangeComponent = ({ toggle, modal, store, dispatch }) => {
  // ** states

  const schema = yup.object().shape({
    old_password: yup.string().required(),
    new_password: yup.string().min(8).required(),
    c_password: yup.string().required().oneOf([yup.ref('new_password')], 'Password must match'),
  })
  // ** Hooks
  const {
    control,
    setError,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) })

  const onSubmit = data => {
    if (isObjEmpty(errors)) {
      toggle()
      dispatch(changePass({
        ...data
      }))
    } else {
      for (const key in data) {
        if (data[key].length === 0) {
          setError(key, {
            type: 'manual'
          })
        }
      }
    }
  }

  return (
    <Fragment>
      <Modal
        isOpen={modal}
        toggle={() => {
          toggle()
        }}
        className='modal-dialog-centered modal-md'
      >
        <ModalHeader
          className='bg-transparent'
          toggle={() => {
            toggle()
          }}
        >
          Change Password
        </ModalHeader>
        <ModalBody>
  
            <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-1'>
                <Label className='form-label' for='old_password'>
                  Old Password
                </Label>
                <Controller
                  id='old_password'
                  name='old_password'
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type='password'  placeholder='Enter Old Password' invalid={errors.old_password && true} />
                  )}
                />
                {errors.old_password && <FormFeedback>{errors.old_password.message}</FormFeedback>}
              </div>
              <div className='mb-1'>
                <Label className='form-label' for='new_password'>
                  New Password
                </Label>
                <Controller
                  id='new_password'
                  name='new_password'
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type='password'  placeholder='Enter New Password' invalid={errors.new_password && true} />
                  )}
                />
                {errors.new_password && <FormFeedback>{errors.new_password.message}</FormFeedback>}
              </div>
              <div className='mb-1'>
                <Label className='form-label' for='c_password'>
                Confirm Password
                </Label>
                <Controller
                  id='c_password'
                  name='c_password'
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type='password'  placeholder='Confirm Password' invalid={errors.c_password && true} />
                  )}
                />
                {errors.c_password && <FormFeedback>{errors.c_password.message}</FormFeedback>}
              </div>
              <Button type='submit' className='me-1' color='primary'>
                Submit
              </Button>
              <Button
                type='reset'
                color='secondary'
                outline
                onClick={() => {
                  toggle()
                }}
              >
                Cancel
              </Button>
            </Form>
         
        </ModalBody>
      </Modal>
    </Fragment>
  )
}
export default UserDropdown
