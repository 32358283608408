// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('products/getAllData', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/product')
  return response.data.result
})

export const getBrands = createAsyncThunk('products/getBrands', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/brand')
  return response.data.data
})
export const getColors = createAsyncThunk('products/getColors', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/color')
  return response.data.data
})
export const getCategories = createAsyncThunk('products/getCategories', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/category')
  return response.data.data
})
export const resetUser = createAsyncThunk('products/resetUser', () => {
  return null
})

export const getData = createAsyncThunk('products/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/product?', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data?.data.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('products/getUser', async id => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/product/${id}`)
  return response.data.data[0]
})

export const addUser = createAsyncThunk('products/addUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/product', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err?.response?.data?.message)
    })
  await dispatch(getData(getState().products.params))
  return user
})

export const addComb = createAsyncThunk('products/addComb', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/variation', params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getUser(params.product_id))
  return user
})

export const updateUser = createAsyncThunk('products/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/product/${params.product_id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err?.response?.data?.message)
      console.log(er?.response?.data?.message)
    })
  await dispatch(getData(getState().products.params))
  await dispatch(getUser(params.product_id))
  return user
})

export const updateComb = createAsyncThunk('products/updateComb', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/variation/${params.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getUser(params.product_id))
  return user
})

export const deleteUser = createAsyncThunk('products/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/product/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().products.params))
  return id
})
export const deleteComb = createAsyncThunk('products/deleteComb', async (params, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/variation/${params.id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getUser(params.product_id))
  return id
})

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    brands: [],
    categories: [],
    colors: [],
    selectedUser: null,
    selectedToEdit: null,
    createData: null,
    loading: false,
    addUserStatus: 'idle'
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.brands = action.payload
      })
      .addCase(getColors.fulfilled, (state, action) => {
        state.colors = action.payload
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(addUser.pending, (state, action) => {
        state.addUserStatus = 'loading'
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.addUserStatus = 'succeeded'
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addUserStatus = 'rejected'
      })
  }
})

export default productsSlice.reducer
