// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {successAlert, errorAlert } from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('blog/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('blog/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/post?with_pagination=yes', params)
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total,
    loading: true
    
  }
})

export const getCategories = createAsyncThunk('blog/getCategories', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/category')
  return {
    categories: response.data.data
  }
})

export const getUser = createAsyncThunk('blog/getUser', async id => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/post/${id}`)
  return {
    data: response.data.data,
   
  }
})
export const resetUser = createAsyncThunk('blog/resetUser', async () => {
  
  return null
})

export const addUser = createAsyncThunk('blog/addUser', async (user, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + '/api/dashboard/post' ,user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().blog.params))
  
  return user
})

export const deleteUser = createAsyncThunk('blog/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/post/${id}`).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().blog.params))
  await dispatch(getAllData())
  return id
})

export const updateUser = createAsyncThunk('blog/updateUser', async (params, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + `/api/dashboard/post/${params.id}`, params.user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().blog.params))
   await dispatch(getAllData())
  return params.user
})

export const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    categories: [],
    selectedUser: null,
    loading:false
   
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        // state.loading = action.payload.loading
       
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload
        // console.log(state.categories)
      })
  }
})

export default blogSlice.reducer
