// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {successAlert, errorAlert } from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('aboutDone/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('aboutDone/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/about', params)

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})





export const getUser = createAsyncThunk('aboutDone/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.data
})

export const updateUser = createAsyncThunk('aboutDone/updateUser', async (params, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + '/api/dashboard/about', params.user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().aboutDone.params))
  // await dispatch(getAllData())
  return params.user
})


export const addUser = createAsyncThunk('aboutDone/addUser', async (user, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + '/api/dashboard/about' ,user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().aboutDone.params))
  
  return user
})

export const aboutDoneSlice = createSlice({
  name: 'aboutDone',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default aboutDoneSlice.reducer
