// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import user from '@src/views/apps/user/store'
import sliders from '@src/views/apps/sliders/store'
import sociallinks from '@src/views/apps/sociallinks/store'
import icons from '@src/views/apps/icons/store'
import partners from '@src/views/apps/partners/store'
import brands from '@src/views/apps/brands/store'
import services from '@src/views/apps/services/store'
import support from '@src/views/apps/support/store'
import aboutDone from '@src/views/apps/aboutDone/store'
import aboutVision from '@src/views/apps/aboutVision/store'
import aboutMission from '@src/views/apps/aboutMission/store'
import categoryData from '@src/views/apps/categoryData/store'
import catContent from '@src/views/apps/catContent/store'
import products from '@src/views/apps/products/store'
import productContent from '@src/views/apps/productContent/store'
import projectContent from '@src/views/apps/projectContent/store'
import projects from '@src/views/apps/projects/store'
import video from '@src/views/apps/video/store'
import reviews from '@src/views/apps/reviews/store'
import contact from '@src/views/apps/contact/store'
import blog from '@src/views/apps/blog/store'
import feq from '@src/views/apps/feq/store'
import aboutMessage from '@src/views/apps/aboutMessage/store'
import aboutGoals from '@src/views/apps/aboutGoals/store'
import aboutQuality from '@src/views/apps/aboutQuality/store'
import reset from '@src/views/pages/authentication/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import contactInfo from '@src/views/apps/contactInfo/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  user,
  sliders,
  projects,
  icons,
  partners,
  sociallinks,
  brands,
  services,
  support,
  aboutDone,
  aboutVision,
  aboutQuality,
  aboutMission,
  aboutMessage,
  aboutGoals,
  catContent,
  categoryData,
  products,
  productContent,
  projectContent,
  video,
  reviews,
  contact,
  blog,
  feq,
  reset,
  dataTables,
  permissions,
  contactInfo
}

export default rootReducer
