// ** Icons Import
import { Server, Grid, Circle, HardDrive } from 'react-feather'

export default [
  {
    header: 'cadeau-dashboard Driver'
  },
  {
    id: 'vehicle',
    title: 'Vehicle Type',
    icon: <HardDrive />,
    navLink: '/apps/vehicle/list'
  },
]
