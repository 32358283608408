// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('reset/getAllData', async () => {
  const response = await axios.get('https://driver.bw-businessworld.com/api/v1/web/brand')
  return response.data.result
})

export const getData = createAsyncThunk('reset/getData', async params => {
  const response = await axios.get(
    'https://apitravel.bw-businessworld.com/api/v1/dashboard/api/auth/cadue/brands/statistics',
    params
  )
  return {
    params,
    today: response.data.data.today,
    l7days: response.data.data.l7days,
    this_month: response.data.data.this_month,
    last_month: response.data.data.last_month
  }
})
export const sendCode = createAsyncThunk('reset/sendCode', async (params, { dispatch, getState }) => {
  await axios
    .post('https://apitravel.bw-businessworld.com/api/v1/dashboard/auth/sendVerificationCodeResetPass', params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
      params.navigate('/pages/verify-code')
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  return user
})
export const getUser = createAsyncThunk('reset/getUser', async id => {
  if (id !== null) {
    const response = await axios.get(
      `https://driver.bw-businessworld.com/api/v1/dashboard/auth/sendVerificationCodeResetPass`
    )
    return response.data.result
  } else {
    return null
  }
})
export const resetUser = createAsyncThunk('reset/resetUser', () => {
  return null
})

export const resetSlice = createSlice({
  name: 'reset',
  initialState: {
    data: null,
    total: 1,
    params: {},
    allData: [],
    today: null,
    l7days: null,
    this_month: null,
    last_month: null,
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.l7days = action.payload.l7days
        state.params = action.payload.params
        state.this_month = action.payload.this_month
        state.last_month = action.payload.last_month
        state.today = action.payload.today
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default resetSlice.reducer
