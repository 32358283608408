// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {successAlert, errorAlert} from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('brands/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('brands/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/brand?with_pagination=yes', params)
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('brands/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('brands/addUser', async (user, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + '/api/dashboard/brand', user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().brands.params))
  // await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('brands/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/brand/${id}`).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().brands.params))
  // await dispatch(getAllData())
  return id
})
export const updateUser = createAsyncThunk('brands/updateUser', async (params, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + `/api/dashboard/brand/${params.id}`, params.user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().brands.params))
  // await dispatch(getAllData())
  return params.user
})


export const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.loading = action.payload.loading
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = action.payload.loading
      })
  }
})

export default brandsSlice.reducer

/*export const uppdetUser = createAsyncThunk('sociallinks/getUser', async (id, { dispatch, getState }) => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/social-links/${id}`)
  await dispatch(getData(getState().sociallinks.params))
  return id
})
* */