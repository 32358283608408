// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { successAlert, errorAlert } from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('contactInfo/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/contactInfo?with_pagination=no', {
    params
  })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total,
    loading: true
  }
})

export const addContactInfo = createAsyncThunk('contactInfo/addContactInfo', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/contactInfo', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData())

  return user
})

export const deleteContactInfo = createAsyncThunk(
  'contactInfo/deleteContactInfo',
  async ({ id }, { dispatch, getState }) => {
    await axios
      .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/contactInfo/${id}`)
      .then(res => {
        successAlert()
      })
      .catch(err => {
        errorAlert()
      })
    await dispatch(getData())
    return id
  }
)

export const updateContactInfo = createAsyncThunk(
  'contactInfo/updateContactInfo',
  async (params, { dispatch, getState }) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/contactInfo/${params?.id}`, params.user)
      .then(res => {
        successAlert()
      })
      .catch(err => {
        errorAlert()
      })
    await dispatch(getData())
    return params.user
  }
)

export const contactInfoSlice = createSlice({
  name: 'contactInfo',
  initialState: {
    contactInfoData: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.contactInfoData = action.payload.data
      ;(state.params = action.payload.params), (state.loading = action.payload.loading)
    })
  }
})

export default contactInfoSlice.reducer
