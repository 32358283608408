// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const changePass = createAsyncThunk('mainUser/changePass', async (user, { dispatch, getState }) => {
  await axios
    .post(`https://apitravel.bw-businessworld.com/api/v1/dashboard/api/setting/change/password`, user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } 
      else if(err?.response?.status === 400){
        errorAlert('Old Password is not correct')
      }
      else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().mainUser.params))
  return user
})

export const mainUserSlice = createSlice({
  name: 'mainUser',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    un_read: null,
    selectedUser: null,
    loading: false
  },
  reducers: {}
})

export default mainUserSlice.reducer
