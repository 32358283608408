// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { successAlert, errorAlert } from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('categoryData/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('categoryData/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/category?with_pagination=yes', {
    params
  })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('categoryData/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.data
})

export const addUser = createAsyncThunk('categoryData/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/category', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().categoryData.params))

  return user
})

export const deleteUser = createAsyncThunk('categoryData/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/category/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().categoryData.params))
  return id
})
export const updateUser = createAsyncThunk('categoryData/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/category/${params.id}`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().categoryData.params))
  // await dispatch(getAllData())
  return params.user
})

export const categoryDataSlice = createSlice({
  name: 'categoryData',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = action.payload.loading
      })
  }
})

export default categoryDataSlice.reducer
