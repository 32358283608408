// ** Icons Import
import { t } from 'i18next'
import {
  Mail,
  Clipboard,
  CheckSquare,
  Calendar,
  FileText,
  Circle,
  Bell,
  User,
  Image,
  Archive,
  Folder,
  ShoppingBag,
  Briefcase,
  Map,
  MessageSquare,
  CreditCard,
  Package,
  Crosshair,
  Link,
  Sliders,
  Users,
  Align_justify,
  Mic,
  ShoppingCart,
  Globe,
  Database,
  Hexagon,
  Box,
  Grid,
  Video,
  Book,
  HelpCircle,
  Phone,
  BookOpen,
  Home,
  Layout,
  Info,
  Shield,
  PhoneCall
} from 'react-feather'

export default [
  {
    id: 'home',
    title: t('Home'),
    icon: <Home size={20} />,
    children: [
      {
        id: 'sliders',
        title: t('Sliders'),
        icon: <Circle size={20} />,
        navLink: '/apps/sliders/list'
      },
      {
        id: 'video',
        title: t('Video Section'),
        icon: <Circle size={20} />,
        navLink: '/apps/video/list'
      },
      // {
      //   id: 'brochure',
      //   title: t('Brochure'),
      //   icon: <Book size={20} />,
      //   navLink: '/apps/brochure/list'
      // },
      {
        id: 'icons',
        title: t('Icons'),
        icon: <Circle size={20} />,
        navLink: '/apps/icons/list'
      }
    ]
  },

  {
    id: 'categoryData',
    title: t('Categories'),
    icon: <Database size={20} />,
    children: [
      {
        id: 'catContent',
        title: t('Category Content'),
        icon: <Circle size={20} />,
        navLink: '/apps/catContent/list'
      },
      {
        id: 'categoryData',
        title: t('Category Data'),
        icon: <Circle size={20} />,
        navLink: '/apps/categoryData/list'
      }
    ]
  },

  {
    id: 'products',
    title: t('Products'),
    icon: <Box size={20} />,
    children: [
      {
        id: 'products',
        title: t('Products'),
        icon: <Circle size={20} />,
        navLink: '/apps/products/list'
      },
      {
        id: 'productContent',
        title: t('Product Content'),
        icon: <Circle size={20} />,
        navLink: '/apps/productContent/list'
      }
    ]
  },
  {
    id: 'projects',
    title: t('Projects'),
    icon: <Layout size={20} />,
    children: [
      {
        id: 'projects',
        title: t('Projects'),
        icon: <Circle size={20} />,
        navLink: '/apps/projects/list'
      },
      {
        id: 'projectContent',
        title: t('Projects Content'),
        icon: <Circle size={20} />,
        navLink: '/apps/projectContent/list'
      }
    ]
  },

  {
    id: 'brands',
    title: t('Brands'),
    icon: <ShoppingCart size={20} />,
    navLink: '/apps/brands/list'
  },
  {
    id: 'services',
    title: t('Services & Support'),
    icon: <Mic size={20} />,
    children: [
      {
        id: 'services',
        title: t('Services'),
        icon: <Circle size={20} />,
        navLink: '/apps/services/list'
      },
      {
        id: 'support',
        title: t('Support'),
        icon: <Circle size={20} />,
        navLink: '/apps/support/list'
      }
    ]
  },

  {
    id: 'sociallinks',
    title: t('Social-links'),
    icon: <Link size={20} />,
    navLink: '/apps/sociallinks/list'
  },

  {
    id: 'reviews',
    title: t('Reviews'),
    icon: <BookOpen size={20} />,
    navLink: '/apps/reviews/list'
  },
  {
    id: 'partners',
    title: t('Partner'),
    icon: <Users size={20} />,
    navLink: '/apps/partners/list'
  },

  {
    id: 'aboutDone',
    title: t('Company Profile'),
    icon: <HelpCircle size={20} />,
    children: [
      {
        id: 'aboutDone',
        title: t('About'),
        icon: <Circle size={20} />,
        navLink: '/apps/aboutDone/list'
      },
      {
        id: 'aboutVision',
        title: t('Our Vision'),
        icon: <Circle size={20} />,
        navLink: '/apps/aboutVision/list'
      },
      {
        id: 'aboutMission',
        title: t('Our Mission'),
        icon: <Circle size={20} />,
        navLink: '/apps/aboutMission/list'
      },
      {
        id: 'aboutMessage',
        title: t('Our Message'),
        icon: <Circle size={20} />,
        navLink: '/apps/aboutMessage/list'
      },
      {
        id: 'aboutGoals',
        title: t('Our Goals'),
        icon: <Circle size={20} />,
        navLink: '/apps/aboutGoals/list'
      },
      {
        id: 'aboutQuality',
        title: t('Our Quality'),
        icon: <Circle size={20} />,
        navLink: '/apps/aboutQuality/list'
      }
    ]
  },
  {
    id: 'contactInfo',
    title: t('Contact Info'),
    icon: <PhoneCall size={20} />,
    navLink: '/apps/contactInfo/list'
  },
  {
    id: 'blog',
    title: t('Blog'),
    icon: <FileText size={20} />,
    navLink: '/apps/blog/list'
  },
  {
    id: 'feq',
    title: t('FAQ'),
    icon: <Info size={20} />,
    navLink: '/apps/feq/list'
  }
  // {
  //   id: 'privacy',
  //   title: 'Privacy Policy',
  //   icon: <Shield size={20} />,
  //   navLink: '/apps/privacy-policy/list'
  // },
  // {
  //   id: 'terms',
  //   title: 'Terms & Conditions',
  //   icon: <FileText size={20} />,
  //   navLink: '/apps/terms-conditions/list'
  // }
]
