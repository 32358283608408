// ** React Imports
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'
import { isUserLoggedIn } from '@utils';

// ** Menu Items Array
import navigation from '@src/navigation/vertical'

const VerticalLayout = props => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')));
    }
  }, []);
  return (
    <Layout menuData={navigation} {...props}>
      <Outlet test={'test'}/>
    </Layout>
  )
}

export default VerticalLayout
