// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { successAlert, errorAlert } from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('reviews/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('reviews/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/review?with_paginate=yes', { params })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total,
    loading: true
  }
})
export const getProduct = createAsyncThunk('products/getData', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/product?with_pagination=yes&per_page=&name=&model_name=&model_number=&in_stock=&hidden=&is_new=&watt_per_hour[0]=&category_id=',
    { params }
  )
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data?.data.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('reviews/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.data
})

export const updateUser = createAsyncThunk('reviews/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/product-content', params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().reviews.params))
  // await dispatch(getAllData())
  return params.user
})

export const addUser = createAsyncThunk('reviews/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/product-content', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().reviews.params))

  return user
})

export const deleteUser = createAsyncThunk('reviews/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/review/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().reviews.params))
  return id
})

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    data: null,
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default reviewsSlice.reducer
