// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const SliderList = lazy(() => import('../../views/apps/sliders/list'))
const IconsList = lazy(() => import('../../views/apps/icons/list'))
const SociallinkList = lazy(() => import('../../views/apps/sociallinks/list'))
const PartnersList = lazy(() => import('../../views/apps/partners/list'))
const BrandsList = lazy(() => import('../../views/apps/brands/list'))
const ServicesList = lazy(() => import('../../views/apps/services/list'))
const SupportList = lazy(() => import('../../views/apps/support/list'))
const AboutDoneList = lazy(() => import('../../views/apps/aboutDone/list'))
const AboutVisionList = lazy(() => import('../../views/apps/aboutVision/list'))
const AboutMissionList = lazy(() => import('../../views/apps/aboutMission/list'))
const CategoryDataList = lazy(() => import('../../views/apps/categoryData/list'))
const CatContentList = lazy(() => import('../../views/apps/catContent/list'))
const ProductsList = lazy(() => import('../../views/apps/products/list'))
const ProductAdd = lazy(() => import('../../views/apps/products/addProduct'))
const ProductEdit = lazy(() => import('../../views/apps/products/editProduct'))
const ProjectContentList = lazy(() => import('../../views/apps/projectContent/list'))
const ProjectsList = lazy(() => import('../../views/apps/projects/list'))
const ProjectAdd = lazy(() => import('../../views/apps/projects/addProject'))
const ProjectEdit = lazy(() => import('../../views/apps/projects/editProject'))
const VideoList = lazy(() => import('../../views/apps/video/list'))
const ReviewsList = lazy(() => import('../../views/apps/reviews/list'))
const ProductContent = lazy(() => import('../../views/apps/productContent/list'))
const ContactList = lazy(() => import('../../views/apps/contact/list'))
const BlogList = lazy(() => import('../../views/apps/blog/list'))
const FeqList = lazy(() => import('../../views/apps/feq/list'))
const AboutMessageList = lazy(() => import('../../views/apps/aboutMessage/list'))
const AboutGoalsList = lazy(() => import('../../views/apps/aboutGoals/list'))
const AboutQualityList = lazy(() => import('../../views/apps/aboutQuality/list'))
const PrivacyList = lazy(() => import('../../views/apps/privacy-policy/list'))
const TermsList = lazy(() => import('../../views/apps/terms-conditions/list'))
const ContactInfoList = lazy(() => import('../../views/apps/contactInfo/list'))

const AppRoutes = [
  {
    element: <SliderList />,
    path: '/apps/sliders/list'
  },
  {
    element: <IconsList />,
    path: '/apps/icons/list'
  },
  {
    element: <SociallinkList />,
    path: '/apps/sociallinks/list'
  },
  {
    element: <PartnersList />,
    path: '/apps/partners/list'
  },
  {
    element: <BrandsList />,
    path: '/apps/brands/list'
  },

  {
    element: <ServicesList />,
    path: '/apps/services/list'
  },
  {
    element: <SupportList />,
    path: '/apps/support/list'
  },
  {
    element: <AboutDoneList />,
    path: '/apps/aboutDone/list'
  },
  {
    element: <AboutVisionList />,
    path: '/apps/aboutVision/list'
  },
  {
    element: <AboutMissionList />,
    path: '/apps/aboutMission/list'
  },
  {
    element: <AboutMessageList />,
    path: '/apps/aboutMessage/list'
  },
  {
    element: <AboutGoalsList />,
    path: '/apps/aboutGoals/list'
  },
  {
    element: <AboutQualityList />,
    path: '/apps/aboutQuality/list'
  },
  {
    element: <VideoList />,
    path: '/apps/video/list'
  },
  {
    element: <ReviewsList />,
    path: '/apps/reviews/list'
  },
  {
    element: <ContactList />,
    path: '/apps/contact/list'
  },

  {
    element: <ProductContent />,
    path: '/apps/productContent/list'
  },
  {
    element: <CategoryDataList />,
    path: '/apps/categoryData/list'
  },
  {
    element: <CatContentList />,
    path: '/apps/catContent/list'
  },
  {
    element: <ProductsList />,
    path: '/apps/products/list'
  },
  {
    element: <ProjectsList />,
    path: '/apps/projects/list'
  },
  {
    element: <ProductAdd />,
    path: '/apps/add-product'
  },
  {
    element: <ProjectAdd />,
    path: '/apps/add-project'
  },
  {
    element: <ProductEdit />,
    path: '/apps/product/edit/:id'
  },
  {
    element: <ProjectEdit />,
    path: '/apps/project/edit/:id'
  },
  {
    element: <ProjectContentList />,
    path: '/apps/projectContent/list'
  },
  {
    element: <BlogList />,
    path: '/apps/blog/list'
  },
  {
    element: <FeqList />,
    path: '/apps/feq/list'
  },
  {
    element: <PrivacyList />,
    path: '/apps/privacy-policy/list'
  },
  {
    element: <TermsList />,
    path: '/apps/terms-conditions/list'
  },
  {
    element: <ContactInfoList />,
    path: '/apps/contactInfo/list'
  }
]

export default AppRoutes
