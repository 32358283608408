// ** Icons Import
import { Box, Menu, Circle, EyeOff, Folder, LifeBuoy, Shield, HardDrive, Anchor, Crosshair, Hexagon } from 'react-feather'

export default [
  {
    id: 'cadeau-dashboard-driver',
    title: 'cadeau-dashboard Driver',
    icon: <LifeBuoy />,
    children: [
      {
        id: 'vehicle',
        title: 'Vehicle Type',
        icon: <Hexagon />,
        navLink: '/apps/vehicle/list'
      },
      {
        id: 'drivers',
        title: 'Drivers',
        icon: <Crosshair />,
        navLink: '/apps/drivers/list'
      },
      {
        id: 'vehiclemain',
        title: 'Vehicles',
        icon: <HardDrive />,
        navLink: '/apps/Vehicle-main/list'
      },
      {
        id: 'trips',
        title: 'Trips',
        icon: <Anchor />,
        navLink: '/apps/trips/list'
      }
    ]
  }
]
