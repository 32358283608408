// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { successAlert, errorAlert } from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('support/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('support/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/support?with_pagination=yes', params)
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('support/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.data
})

export const addUser = createAsyncThunk('support/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/support', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().support.params))

  return user
})

export const deleteUser = createAsyncThunk('support/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/support/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().support.params))
  return id
})
export const updateUser = createAsyncThunk('support/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/support/${params.id}`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().support.params))
  // await dispatch(getAllData())
  return params.user
})

export const supportSlice = createSlice({
  name: 'support',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = action.payload.loading
      })
  }
})

export default supportSlice.reducer
