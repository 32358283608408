// ** Icons Import
import { Server, Grid, Circle, HardDrive, Anchor, Crosshair, Hexagon } from 'react-feather'

export default [
  {
    header: 'cadeau-dashboard Driver'
  },
  {
    id: 'vehicle',
    title: 'Vehicle Type',
    icon: <Hexagon />,
    navLink: '/apps/vehicle/list'
  },
  {
    id: 'drivers',
    title: 'Drivers',
    icon: <Crosshair />,
    navLink: '/apps/drivers/list'
  },
  {
    id: 'vehiclemain',
    title: 'Vehicles',
    icon: <HardDrive />,
    navLink: '/apps/Vehicle-main/list'
  },
  {
    id: 'trips',
    title: 'Trips',
    icon: <Anchor />,
    navLink: '/apps/trips/list'
  },
]
