// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {successAlert, errorAlert} from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('sliders/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})
export const resetUser = createAsyncThunk('sliders/resetUser', async () => {
  
  return null
})
export const getData = createAsyncThunk('sliders/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/home-slider', params)

  return {
    params,
    data: response.data.data,
    totalPages: response.data.total,
    loading: false
  }
})

export const getUser = createAsyncThunk('sliders/getUser', async id => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/home-slider/${id}`)
  return {
    data: response.data.data[0],
    loading: false,
  }
})

export const addUser = createAsyncThunk('sliders/addUser', async (user, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + '/api/dashboard/home/slider', user).then(res => {
  successAlert()
}).catch(err => {
  errorAlert()
})
  await dispatch(getData())
  // await dispatch(getAllData())
  return user
})


export const updateUser = createAsyncThunk('categoryData/updateUser', async (params, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + `/api/dashboard/home-slider/${params.id}`, params.user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().categoryData.params))
  // await dispatch(getAllData())
  return  params.user
})

export const deleteUser = createAsyncThunk('sliders/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/home-slider/${id}`, { id }).then(res => {
  successAlert()})
  await dispatch(getData())
  // await dispatch(getAllData())
  return id
})

export const slidersSlice = createSlice({
  name: 'sliders',
  initialState: {
    data: null,
    sliderData: null,
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
        state.selectedUser = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.sliderData = action.payload.data
        // state.selectedUser = action.payload
        state.loading = action.payload.loading
      })
  }
})

export default slidersSlice.reducer
