// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {successAlert, errorAlert} from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('projectContent/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('projectContent/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/project-content', params)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})



export const getUser = createAsyncThunk('projectContent/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.data
})

export const addUser = createAsyncThunk('projectContent/addUser', async (user, { dispatch, getState }) => {
  await axios.put(process.env.REACT_APP_BASE_URL + '/api/dashboard/project-content' ,user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().projectContent.params))
  
  return user
})

export const updateUser = createAsyncThunk('projectContent/updateUser', async (params, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + '/api/dashboard/project-content', params.user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().projectContent.params))
  // await dispatch(getAllData())
  return params.user
})

/*export const deleteUser = createAsyncThunk('projectContent/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/home-slider/${id}`)
  await dispatch(getData(getState().projectContent.params))
  await dispatch(getAllData())
  return id
})
export const updateUserVision = createAsyncThunk('projectContent/updateUser', async (params, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + `/api/dashboard/home/${params.id}`, params.user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().projectContent.params))
  // await dispatch(getAllData())
  return params.user
})
export const updateUserMission = createAsyncThunk('projectContent/updateUser', async (params, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + `/api/dashboard/home/${params.id}`, params.user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().projectContent.params))
  // await dispatch(getAllData())
  return params.user
})
export const updateUser = createAsyncThunk('projectContent/updateUser', async (params, { dispatch, getState }) => {
  await axios.post(process.env.REACT_APP_BASE_URL + `/api/dashboard/home/${params.id}`, params.user).then(res => {
    successAlert()
  }).catch(err => {
    errorAlert()
  })
  await dispatch(getData(getState().projectContent.params))
  // await dispatch(getAllData())
  return params.user
})
*/
export const projectContentSlice = createSlice({
  name: 'projectContent',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default projectContentSlice.reducer
