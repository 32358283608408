// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { successAlert, errorAlert } from '@alert'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('video/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('video/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/home-video', params)
  return {
    params,
    data: response.data.data?.video_icons,
    link: response.data.data?.video_link,
    totalPages: response.data.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('video/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.data
})

export const addUser = createAsyncThunk('video/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/home-video', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().video.params))

  return user
})
export const uploadVideo = createAsyncThunk('video/uploadVideo', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/home-video-link', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().video.params))
  return user
})

export const addVideo = createAsyncThunk('video/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/home-video-link', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().video.params))

  return user
})

export const deleteUser = createAsyncThunk('video/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/home-video/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().video.params))

  await dispatch(getAllData())
  return id
})
export const updateUser = createAsyncThunk('video/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/home-video/${params.id}`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert()
    })
  await dispatch(getData(getState().video.params))
  // await dispatch(getAllData())
  return params.user
})

export const videoSlice = createSlice({
  name: 'video',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    link: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.link = action.payload.link
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = action.payload.loading
      })
  }
})

export default videoSlice.reducer
